import React from 'react';
import ModalPopUp from "../Modal/Modal"
import ModalWhatsApp from '../Modal/ModalWhatsApp'
import ModalPolitica from '../ModalPolitica';


const Footer = () => {

  return (
   
          <div className="d-flex flex-column flex-sm-row  justify-content-center bg-dark  py-4 mt-5 "  >
            <p style={{fontSize:"14px"}} className='text-center'>&copy; 2023 Smart Web Lab. Toate drepturile rezervate
           
            
        <p >

            <ModalPolitica/>
           </p>
        </p>
           
          </div>
     
  );
};
export default Footer;

