import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalPopUp() {
  const [show, setShow] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const handleClose = () => {
    setShow(false);
    setIsSent(false);
  };
  const handleShow = () => setShow(true);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_wj5qx4t', 'template_0x43n4y', form.current, 'VCE-xGBtxRIB8WEQ2')
      .then(
        (result) => {
          console.log(result.text);
          setIsSent(true);
          setTimeout(() => {
            handleClose(); // Închide modalul după 3 secunde
          }, 3000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <Button onClick={handleShow} variant="info" className='btn-sm'>
        Cere o cotatie
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title className="text-dark" style={{ fontWeight: 'bold' }}>
            Contactează-ne
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-dark text-bold">
          <div className="row">
            <form ref={form} onSubmit={sendEmail}>
              <div className="col-12 mb-3">
                <label htmlFor="name">Nume și Prenume</label>
                <input type="text" id="name" name='user_name' className="form-control" required/>
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="companie">Denumire Companie</label>
                <input type="text" id="companie" name='user_company' className="form-control"required />
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="user_email" className="form-control" required />
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="tel">Telefon</label>
                <input type="text" id="tel" name="user_phone" className="form-control" required/>
              </div>
              <div className="col-12">
                <label htmlFor="mesaj">Mesaj</label>
                <textarea
                  type="text"
                  rows={5}
                  id="mesaj"
                  className="form-control"
                  name='message'
                  placeholder="Povestește-ne despre proiectul tău.."
                  required
                ></textarea>
              </div>
              <div className="col-12 mt-2">
                <button className="btn btn-info w-100" type='submit'>
                  Trimite
                </button>
              </div>
            </form>
          </div>
          {isSent && (
            <div className="alert alert-success mt-3">
              Mesaj trimis cu succes!
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Închide
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalPopUp;
