import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalPolitica() {
    const [show, setShow] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const handleClose = () => {
        setShow(false);
        setIsSent(false);
    };
    const handleShow = () => setShow(true);

    const [hover, setHover] = useState(false);

    return (
        <>
            <p onClick={handleShow} onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                style={{
                    fontSize: "12px",
                    cursor: "pointer",
                    color: hover ? "#0ef" : "#fff",
                }} >
                Politica de confidențialitate
            </p>

            <Modal show={show}  onHide={handleClose} dialogClassName='modal-xl'>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark" style={{ fontWeight: 'bold' }}>
                        Politica de confidențialitate
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-dark text-bold " >
                    <p><strong>Introducere</strong></p>
                    <p>La SWL, ne angajăm să protejăm și să respectăm confidențialitatea și securitatea datelor personale ale clienților, angajaților și partenerilor noștri. Această politică de confidențialitate explică modul în care colectăm, utilizăm, stocăm și protejăm informațiile tale personale, în conformitate cu Regulamentul (UE) 2016/679 al Parlamentului European și al Consiliului (GDPR).</p>

                    <p><strong>1. Informațiile pe care le colectăm</strong></p>
                    <p>Colectăm și prelucrăm următoarele categorii de date personale:</p>
                    <ul>
                        <li>Date de identificare: nume, prenume, adresă de e-mail, număr de telefon;</li>
                        <li>Date de localizare: adresa IP, locația geografică;</li>
                        <li>Date de contact: adresa poștală, informații privind conturile bancare, dacă este cazul;</li>
                        <li>Alte date necesare pentru furnizarea serviciilor noastre (ex: detalii privind tranzacțiile financiare, istoricul comenzii, etc.).</li>
                    </ul>

                    <p><strong>2. Scopurile prelucrării datelor</strong></p>
                    <p>Datele personale colectate vor fi utilizate pentru următoarele scopuri:</p>
                    <ul>
                        <li>Furnizarea și administrarea serviciilor noastre;</li>
                        <li>Gestionarea comenzilor, facturarea și livrarea produselor/serviciilor;</li>
                        <li>Îmbunătățirea experienței utilizatorilor pe platforma noastră;</li>
                        <li>Respectarea cerințelor legale și fiscale;</li>
                        <li>Comunicare directă cu utilizatorii, în scopuri de marketing (doar cu consimțământul prealabil);</li>
                        <li>Răspunsuri la întrebările și solicitările utilizatorilor.</li>
                    </ul>

                    <p><strong>3. Temeiul legal al prelucrării datelor</strong></p>
                    <p>Prelucrăm datele personale pe baza următoarelor temeiuri legale:</p>
                    <ul>
                        <li>Consimțământul utilizatorului (pentru activități de marketing direct);</li>
                        <li>Executarea unui contract (pentru furnizarea serviciilor sau produselor solicitate);</li>
                        <li>Obligații legale (pentru îndeplinirea obligațiilor fiscale și legale).</li>
                    </ul>

                    <p><strong>4. Destinatarii datelor personale</strong></p>
                    <p>Datele tale personale nu vor fi partajate cu terțe părți, exceptând cazurile în care:</p>
                    <ul>
                        <li>Este necesar pentru a îndeplini un contract (de exemplu, parteneri de livrare);</li>
                        <li>Suntem obligați să respectăm obligații legale (ex: autorități fiscale, instanțe de judecată).</li>
                    </ul>

                    <p><strong>5. Perioada de păstrare a datelor</strong></p>
                    <p>Datele tale personale vor fi păstrate pe perioada necesară pentru îndeplinirea scopurilor menționate mai sus și pentru respectarea obligațiilor legale sau contractuale, dar nu mai mult de 5 ani de la ultima interacțiune cu firma noastră.</p>

                    <p><strong>6. Drepturile tale</strong></p>
                    <p>Conform GDPR, ai următoarele drepturi:</p>
                    <ul>
                        <li>Dreptul de acces: poți solicita o copie a datelor tale personale pe care le deținem;</li>
                        <li>Dreptul de rectificare: poți solicita corectarea datelor tale personale dacă sunt incorecte sau incomplete;</li>
                        <li>Dreptul de ștergere: poți solicita ștergerea datelor tale personale, cu excepția cazurilor în care trebuie să le păstrăm din motive legale;</li>
                        <li>Dreptul de restricționare a prelucrării: poți solicita restricționarea prelucrării datelor tale personale în anumite circumstanțe;</li>
                        <li>Dreptul de a te opune prelucrării: poți solicita să nu îți prelucrăm datele pentru scopuri de marketing direct;</li>
                        <li>Dreptul de portabilitate: poți solicita să îți transmitem datele personale într-un format structurat, utilizabil.</li>
                    </ul>
                    <p>Pentru a exercita oricare dintre aceste drepturi, te rugăm să ne contactezi la adresa [email-ul firmei] sau să ne trimiți o cerere scrisă la sediul nostru.</p>

                    <p><strong>7. Măsuri de securitate</strong></p>
                    <p>SWL implementează măsuri tehnice și organizatorice adecvate pentru protejarea datelor tale personale împotriva accesului neautorizat, distrugerii accidentale sau ilegale, pierderii, utilizării sau divulgării neautorizate.</p>

                    <p><strong>8. Modificări ale politicii de confidențialitate</strong></p>
                    <p>Această politică poate fi actualizată periodic, iar modificările vor fi comunicate pe site-ul nostru. Te rugăm să verifici periodic această politică pentru orice actualizări.</p>

                    <p><strong>9. Contact</strong></p>
                    <p>Pentru întrebări sau nelămuriri referitoare la politica de confidențialitate, te rugăm să ne contactezi la:</p>
                    <ul>
                        <li>Email: site@smartweblab.ro</li>
                        <li>Telefon: +40 742 041 751</li>
                       
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-info btn-sm" onClick={handleClose}>
                        Închide
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ModalPolitica;
