import React from 'react'
import { Link } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import webdesign from '../About/webdesign.png';
import mentenanta from '../About/mentenanta.png';
import actualizare from '../About/actualizare.png';

const ServicesPlans = () => {

  const servicii = [
    {
      name: 'Web Development',
      facilitate1:"Adaptabilitate la diferite ecrane",
      facilitate2:"O experiență de utilizare ușoară",
      facilitate3: "Design estestic",
      facilitate4:"Viteză de încărcare optimizată",
      img: webdesign,
      link: '/servicii',
    },
    {
      name: 'Actualizare',
      facilitate1:"Adăugarea conținutului nou",
      facilitate2:"Optimizarea performanței",
      facilitate3: "Extinderea funcționalității",
      facilitate4:"Eliminarea informațiilor depășite",
      img: actualizare,
      link: '/servicii',
    },
    {
      name: 'Mentenanță',
      facilitate1:"Suport tehnic eficient",
      facilitate2:"Securitate îmbunătățită",
      facilitate3: "Performanță optimizată",
      facilitate4:"Optimizare SEO",
      img: mentenanta,
      link: '/servicii',
    },
  ];

  return (
    <div className="services-plan py-5">
    <div className="container">
      <div className="row">
     {
        servicii.map((serviciu,index) => (

        <div className="col-lg-4" key={index}>
          <div className="card mb-5 mb-lg-0" style={{backgroundColor:"rgba(3, 3, 3, 0.5)" }}>
            <div className="card-body" >
              <h4 className=" text-center ">{serviciu.name}</h4>
              <div className='text-center '>
              <img className='img-fluid ' src={serviciu.img} style={{maxHeight:"200px"}}/>
              </div>
              <hr/>
              <ul className="fa-ul">
                    <li><span className="fa-li text-sm"><i className="fas fa-check"></i></span >{serviciu.facilitate1}</li>
                    <li><span className="fa-li text-sm"><i className="fas fa-check"></i></span >{serviciu.facilitate2}</li>
                    <li><span className="fa-li text-sm"><i className="fas fa-check"></i></span >{serviciu.facilitate3}</li>
                    <li><span className="fa-li text-sm"><i className="fas fa-check"></i></span >{serviciu.facilitate4}</li>
               
              </ul>
              <div className="col text-center">
                <Link to={serviciu.link} className="btn btn-info btn-sm" onClick={() => animateScroll.scrollToTop({ duration: 50 })}>Mai multe..</Link>
              </div>
            </div>
          </div>
        </div>
        )
     
         )
        }
     
      </div>
    </div>
  </div>
  )
}

export default ServicesPlans