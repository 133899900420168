import React, { useState, useEffect  } from "react";
import Nav from "../Navbar/Nav";
import "../Servicii/servicii.css";

import Footer from "../Footer/Footer";
import mentenanta from "./mentenanta.png";
import actualizare from "./actualizare.png";
import webdesign from "./webdesign.png";


const Servicii = () => {
  const services = [
    {
      name: "Web Design Personalizat",
      description:
        " Serviciul nostru de Web Design Personalizat vă ajută să transformați viziunile și ideile dvs. în realitate digitală. Echipa noastră de designeri talentați lucrează îndeaproape cu dvs. pentru a crea un site web unic, atractiv și adaptat brandului dvs. Cu un design web personalizat, veți beneficia de o prezență online de neegalat care se evidențiază în fața concurenței.",
        facilitate1:"Adaptabilitate la diferite ecrane",
        facilitate2:"O experiență de utilizare ușoară",
        facilitate3: "Design estestic",
        facilitate4:"Viteză de încărcare optimizată",
        imagine: webdesign,
    },
    {
      name: "Mentenanța Website-ului",
      description:
        "Serviciul de Mentenanță Website-ului oferit de Smart Web Lab vă permite să vă concentrați asupra conținutului și a obiectivelor dvs. în timp ce ne ocupăm de funcționalitatea și sănătatea site-ului dvs. Echipa noastră monitorizează site-ul în permanență, efectuează actualizări de securitate și înlătură orice probleme tehnice pentru a vă asigura că site-ul dvs. funcționează optim. ",
        facilitate1:"Suport tehnic eficient",
        facilitate2:"Securitate îmbunătățită",
        facilitate3: "Performanță optimizată",
        facilitate4:"Optimizare SEO",
        imagine: mentenanta,
    },
    {
      name: "Actualizare Website",
      description:
        "Cu serviciul de Actualizare Website de la Smart Web Lab, puteți menține conținutul site-ului dvs. proaspăt și actualizat. Echipa noastră adaugă și modifică conținutul, actualizează grafica și funcționalitățile conform nevoilor dvs. Astfel, site-ul dvs. rămâne relevant și eficient în atingerea obiectivelor dvs. online.",
        facilitate1:"Adăugarea conținutului nou",
      facilitate2:"Optimizarea performanței",
      facilitate3: "Extinderea funcționalității",
      facilitate4:"Eliminarea informațiilor depășite",
        imagine: actualizare,
    },
  ];
  const [selectedService, setSelectedService] = useState(null);
  const [showPret, setShowPret] = useState(false);

  useEffect(() => {
    setSelectedService(services[0]);
  }, []);

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setShowPret(false);
  };

  const handlePretClick = () => {
    setShowPret(true);
  };
  return (
    <>
      <Nav />
      <div className="container bg-dark py-5 mt-5">
        <div className="section-title pt-5">
          <h2 className="slideLeft1">Servicii</h2>
          <p className="slideLeft2">Suntem cu tine</p>
        </div>
        <div className="row ">
          <div>
            <p
              className="col  py-3 px-3 mb-5 mt-3 animation"
              style={{ borderRadius: "20px" }}
            >
              Smart Web Lab vă oferă aceste servicii pentru a vă ajuta să aveți
              o prezență online puternică și eficientă. Cu echipa noastră de
              experți în web design și dezvoltare, suntem dedicați să vă ajutăm
              să atingeți succesul online. Indiferent de nevoile dvs., suntem
              aici pentru a vă oferi soluții personalizate și suport de înaltă
              calitate pentru website-ul dvs.
            </p>
          </div>
        

        <div className="col-sm-3 slideLeft2">
          <ul id="servicii-flters">
            <h5 className="mb-3 fw-bold">Listă Servicii</h5>
            {services.map((service, index) => (
              <li
                key={index}
                className={
                  selectedService && selectedService.name === service.name
                    ? "filter-active"
                    : ""
                }
                onClick={() => handleServiceClick(service)}
              >
                {service.name}
              </li>
            ))}
          </ul>

          {/* <div className="text-center">
            <button
              className="btn btn-outline-info btn-sm mb-3 fw-bold buton-animat"
              onClick={handlePretClick}
            >
              Afișare Preț{" "}
            </button>
            <div>
              {showPret && (
                <p
                  className="text-left background p-2"
                  style={{ borderRadius: "20px", fontSize:"13px" }}
                >
                  Oferta de preț pentru {selectedService.name} dvs. va fi
                  stabilită pe baza complexității proiectului.
                </p>
              )}
            </div>
          </div> */}
        </div>

        <div className="col-sm-9 animation">
          {selectedService && (
            <div
              className="background  py-3 px-3 "
              style={{ borderRadius: "20px" }}
            >
              <h2 className="fw-bold mb-5 text-center ">
                {selectedService.name}
              </h2>
              <div className="row">
                <div className="col-12 text-center ">
                  <img
                    src={selectedService.imagine}
                    className="img-fluid servicii-img"
                    alt=""
                    style={{ maxWidth: "20rem", maxHeight: "20rem" }}
                  />
                </div>
                <div className="col-sm-12 mt-5 ">
                  <p>{selectedService.description}</p>
                  <p>Cu noi befeficiați de :</p>
                  <ul class="fa-ul">
                  <li><span class="fa-li"><i class="fas fa-check"></i></span>{selectedService.facilitate1}</li>
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>{selectedService.facilitate2}</li>
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>{selectedService.facilitate3}</li>
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>{selectedService.facilitate4}</li>
                    
                    </ul>
                </div>
              </div>
            </div>
          )}
        </div>
        </div>
      </div>
      <Footer />
     
    </>
  );
};

export default Servicii;
