import React from 'react'
import Nav from '../Navbar/Nav'
import Portfolio from '../Portfolio/Portfolio'
import Footer from '../Footer/Footer'

 const PortofoliuPage = () => {
  const showButton = false; 
  const showDescription = true
  const showCategory = false
  
 
  return (
    <>
    
    <Nav/>
    <div className='mt-3 '>

    <Portfolio showButton={showButton} showDescription={showDescription} showCategory={showCategory} limit={99}/>
    </div>
    <div className='mt-1'>
    <Footer/>
    </div>
  
  </>
  )
}

export default PortofoliuPage;