import React from 'react'
import Nav from '../Navbar/Nav'
import ModalWhatsApp from '../Modal/ModalWhatsApp'
import "../ContactPage/contactColumn.css"
import ModalPopUp from '../Modal/Modal'

const ContactLanding = () => {



  return (
    <>
    
    <Nav/>
    
    <div className='bg-dark'>
   
    <div className="container bg-dark" id='contact' >
        <div>
        <div className="section-title pt-5">
                <h2 className='slideLeft1'>Ai un proiect?</h2>
                <p className='slideLeft2'>Contactează-ne</p>
              </div>
          <div className='row align-items-center'>
            <div className="col-sm-8 ">
              <div>
                <p  className="  p-4" style={{borderRadius:"20px"}}>Oferim servicii de înaltă calitate, desfășurate exclusiv online. Echipa noastră lucrează în mod 100% remote, asigurându-vă soluții eficiente și flexibile, oriunde vă aflați.</p>
              </div>
           

            </div>

            <div className="col-sm-4 mt-3 text-center  mb-4  slideLeft3" >
             <div className='mb-2'>
              <ModalPopUp  />

             </div>
             <div>

              <ModalWhatsApp />
             </div>
            </div>
          </div>
          <div className="row gy-4">

    <div    >
<div className="container">


      <div className="row gy-4 align-items-center" >
       

        <div className="col-sm-4">
        <div className="col-sm-12 d-flex flex-column align-items-center justify-content-center   text-center background" style={{borderRadius:"20px"}}>
          
            <p className='pt-3'>Luni - Vineri</p>
            <i className="bi bi-clock" style={{marginTop:"-15px",fontSize:"1.5rem"}}></i>
            <p>09:00 - 17:00</p>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="col-sm-12 d-flex flex-column align-items-center justify-content-center text-center background" style={{borderRadius:"20px"}} >
          <p className='pt-3 mb-0'>Email</p>
            <i className="bi bi-envelope " style={{fontSize:"1.5rem"}}></i>
             <p>site@smartweblab.ro</p>
             
          </div>
        </div> 

        <div className="col-sm-4">
        <div className="col-sm-12 d-flex flex-column  justify-content-center text-center background" style={{borderRadius:"20px"}} >
           <p className='pt-3 mb-0'>Telefon</p>
            <i className="bi bi-telephone" style={{fontSize:"1.5rem"}}></i>
            <a href="tel:+40742041751"> 
          <p>+40 742 041 751</p>
          </a>
          
          </div>
        </div>

      </div>

          <div className="d-flex flex-column flex-sm-row justify-content-center border-top border-info py-4 mt-5 " >
            <p>&copy; 2023 Smart Web Lab. Toate drepturile rezervate</p>
            
          </div>
    </div>
  

    </div>

  </div>
        </div>
      </div>
  
    </div>
  
  </>
  )
}

export default ContactLanding