import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
// Asigurați-vă că calea către data.json este corectă
import '../Portfolio/portfolio.css'; // Importați stilurile CSS necesare
import { Link } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

// Componenta Lightbox
function Lightbox({ data, currentImage, closeLightbox, goToPreviousImage, goToNextImage }) {
  // Verificăm dacă data[currentImage].mainImage este definit și nu este null sau undefined
  const mainImage = data[currentImage].mainImage && Object.values(data[currentImage].mainImage);

  return (
    <div className='myModal'>
      <div className="header">
        <h5 id="example" className="text">
          {data[currentImage].title} {/* Schimbați acesta cu titlul proiectului */}
        </h5>
        <span className="close" onClick={closeLightbox}>
          &times;
        </span>
      </div>
      <div className="container">
        {/* Verificăm dacă mainImage este definit înainte de a utiliza Object.values() */}
        {mainImage && (
          <div className='d-flex align-items-center'>
            <div className='col-sm-1  text-center'>

              <button
                className={`prev ${currentImage === 0 ? 'disabled' : ''}`}
                onClick={goToPreviousImage}
                disabled={currentImage === 0}
              >
                <i className="fa-solid fa-angle-left" style={{ fontSize: "2rem" }}></i>
              </button>

            </div>
            <div className='col-sm-10 '>
              <img src={mainImage[0]} className='img-fluid' alt={mainImage[0].title} />
            </div>
            <div className='col-sm-1 text-center'>
              <button
                className={`next ${currentImage === data.length - 1 ? 'disabled' : ''}`}
                onClick={goToNextImage}
                disabled={currentImage === data.length - 1}
              >
                <i class="fa-solid fa-angle-right" style={{ fontSize: "2rem" }}></i>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const Portfolio = ({ showButton, showDescription, showCategory, limit }) => {


  const firebaseConfig = {
    apiKey: "AIzaSyB8XIlbg4hGJj4lthImbvTtJOZC8dNDF4g",
    authDomain: "projects-v3.firebaseapp.com",
    projectId: "projects-v3",
    storageBucket: "projects-v3.appspot.com",
    messagingSenderId: "464029013720",
    appId: "1:464029013720:web:21346c59f4f31d8e18e9ad"
  };

  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);

  const [data, setData] = useState([]);
  // Stare locală pentru lightbox
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);



  // Funcția pentru a deschide lightbox-ul
  const openLightbox = (imageIndex) => {
    setCurrentImage(imageIndex);
    setLightboxOpen(true);
  };

  // Funcția pentru a închide lightbox-ul
  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const goToPreviousImage = () => {
    if (currentImage > 0) {
      setCurrentImage(currentImage - 1);
    }
  };

  // Funcția pentru a naviga la imaginea următoare
  const goToNextImage = () => {
    if (currentImage < data.length - 1) {
      setCurrentImage(currentImage + 1);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postCollectionRef = collection(firestore, 'post');
        const querySnapshot = await getDocs(postCollectionRef);
        const dataArray = [];
        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          dataArray.push({ id: doc.id, ...docData }); // Adaugă ID-ul documentului în obiectul de date
        });

        setData(dataArray);
      } catch (error) {
      }
    };
    fetchData();

  }, [firestore]);



  // Stare locală pentru categoria curentă
  const [currentCategory, setCurrentCategory] = useState('*'); // Inițial, afișează toate proiectele

  // Funcție pentru a actualiza categoria curentă la clic pe un filtru
  const handleFilterClick = (category) => {
    setCurrentCategory(category);


  };
  // Adaugă efectul useEffect pentru a monitoriza categoria curentă
  useEffect(() => {


  }, [currentCategory]);

  return (
    <>

      <div id="portofoliu" className="portfolio bg-dark">
        <div className="container py-5 ">
          <div className="row ">
            <div className="col-sm-10 mb-3">
              <div className="section-title pt-5">
                <h2 className='slideLeft1'>Portofoliu</h2>
                <p className='slideLeft2'>Șabloane Web Personalizabile</p>
              </div>
            </div>

          </div>

          {showDescription && (
            <div className="row">
              <div className="col-12 animation">
                <p className='text-left py-3 px-3 lh-2' style={{ borderRadius: "20px" }}>

                  Creăm site-uri web cu design modern și atractiv,
                  care atrag atenția vizitatorilor și le oferă o experiență de neuitat. <br /> Site-ul dvs. web este prima impresie pe care o faceți online.
                  {/* <br/>Fiecare șablon poate fi construit după preferințele tale. Nu suntem doar dezvoltatori web, suntem și consultanți. 
                <br/> Vom asigura că site-ul dvs. este complet receptiv și se adaptează perfect la toate tipurile de dispozitive, de la computere la smartphone-uri. */}
                </p>
              </div>
            </div>)}

          {showCategory && (
            <div className="row py-3">
              <div className="col-lg-12 d-flex justify-content-center slideLeft3">
                <ul id="portfolio-flters">
                  <li data-filter="*" className={currentCategory === '*' ? 'filter-active' : ''} onClick={() => handleFilterClick('*')}>Toate categoriile</li>
                  <li data-filter=".filter-auto" className={currentCategory === '.filter-auto' ? 'filter-active' : ''} onClick={() => handleFilterClick('.filter-auto')}>Auto</li>
                  <li data-filter=".filter-constructii" className={currentCategory === '.filter-constructii' ? 'filter-active' : ''} onClick={() => handleFilterClick('.filter-constructii')}>Construcții</li>
                  <li data-filter=".filter-restaurant" className={currentCategory === '.filter-restaurant' ? 'filter-active' : ''} onClick={() => handleFilterClick('.filter-restaurant')}>Restaurant</li>
                  <li data-filter=".filter-transport" className={currentCategory === '.filter-transport' ? 'filter-active' : ''} onClick={() => handleFilterClick('.filter-transport')}>Transport</li>
                  <li data-filter=".filter-comercial" className={currentCategory === '.filter-comercial' ? 'filter-active' : ''} onClick={() => handleFilterClick('.filter-comercial')}>Magazin online</li>
                </ul>
              </div>
            </div>)}


          <div className="row portfolio-container">
            {data.slice(0, limit || 8).map((project, index) => {
              // Verifică dacă proiectul aparține categoriei curente sau dacă categoria curentă este "*"
              if (currentCategory === '*' || project.category === currentCategory) {
                return (
                  <div className={`col-lg-4 col-md-4 col-xl-3 col-sm-12  portfolio-item ${project.category}`} key={project.id}>
                    {project.mainImage && Object.keys(project.mainImage).map((key) => (
                      <div className="portfolio-wrap" key={`${project.id}-${key}`}>
                        <div className="background p-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'fit-content' }}>
                          <img
                            src={project.mainImage[key]}
                            alt={project.title}
                            className="img-fluid"
                            style={{ width: "416px", height: "100%", objectFit: "contain" }}
                          />
                        </div>
                        <div className="portfolio-info">
                          <h4>{project.title}</h4>

                          <div className="portfolio-links">
                            <i className="fa-solid fa-magnifying-glass-plus fa-xl" onClick={() => openLightbox(index)}></i>
                            {/* <Link to={`/projectdetail/${project.id}`} className="portfolio-details-lightbox" title="Portfolio Details"><i className="bx bx-link"></i></Link> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              }
              return null; // Dacă proiectul nu aparține categoriei curente, returnează null pentru a-l exclude din afișare
            })}
          </div>
          {showButton && (

            <div className='d-flex justify-content-center mt-3'>

              <Link to="/portofoliu" className='btn btn-info btn-sm ' onClick={() => animateScroll.scrollToTop({ duration: 50 })}>Vezi portfolio</Link>
            </div>
          )
          }
        </div>

        {lightboxOpen && (
          <Lightbox
            data={data}
            currentImage={currentImage}
            closeLightbox={closeLightbox}
            goToPreviousImage={goToPreviousImage} // Transmiteți funcția pentru butonul "Prev"
            goToNextImage={goToNextImage}
          />
        )}
      </div>
    </>
  );
};

export default Portfolio;
