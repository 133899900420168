import React, { useEffect, useState } from 'react';
 // Importați componenta "ParticleBackground"
import Nav from "../Navbar/Nav";
import Hero from "../Hero/Hero";
import About from "../About/About";
import Portfolio from '../Portfolio/Portfolio';
import Footer from '../Footer/Footer';
import "../LandingPage/landingPageStyle.css"
import ContactLanding from '../ContactLanding/ContactLanding';
import ContactPage from "../ContactPage/ContactPage"



const LandingPage = () => {
  const showButton = false;
  const showDescription = false;
  const showCategory = false;

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // Adăugați un eveniment de ascultare a derulării pentru a verifica când elementul intră în vizualizare
    const handleScroll = () => {
      const yOffset = window.scrollY;
    
      const elementsToAnimate = [
        { id: 'animated-about', className: 'animate' },
        { id: 'animated-portfolio', className: 'animate' },
        { id: 'animated-footer', className: 'animate' },
      ];

      elementsToAnimate.forEach((elementInfo) => {
        const element = document.getElementById(elementInfo.id);

        if (element && !isScrolled && yOffset + window.innerHeight > element.offsetTop) {
          setIsScrolled(true);
          element.classList.add(elementInfo.className); // Adăugați clasa pentru animație
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);

  return (
    <>
       {/* Adăugați style pentru a acoperi întreaga pagină */}
      <Nav />
      <Hero  />
      <div id="animated-about" className={`animated-element ${isScrolled ? 'animate' : ''}`}>
      <About />
      </div>
      <Portfolio showButton={showButton}  showDescription={showDescription} showCategory={showCategory} />
      <ContactLanding/>
      
    </>
  );
};

export default LandingPage;
